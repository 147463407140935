import spriteRunLeft from "../images/spriteRunLeft-min.png";
import spriteRunRight from "../images/spriteRunRight-min.png";
import spriteStandLeft from "../images/spriteStandLeft-min.png";
import spriteStandRight from "../images/spriteStandRight-min.png";
import { createImage } from "./utils";
import { constants } from "./utils";

export default class Player {
  constructor({ canvas, canvasContext, frameRate = 1 }) {
    let isMobile = canvas.height > canvas.width;
    let standWidth = isMobile ? 100 : 40;
    let runWidth = isMobile ? 180 : 80;
    let height = isMobile ? 250 : 90;
    this.gravity = isMobile ? constants.mobileGravity : constants.gravity;
    this.position = {
      x: 100,
      y: 100,
    };
    this.velocity = {
      x: 0,
      y: 1,
    };

    this.width = standWidth; //40 90
    this.height = height;
    this.frames = 0;
    this.sprites = {
      standRight: {
        image: createImage(spriteStandRight),
        cropWidth: 177,
        width: standWidth,
      },
      standLeft: {
        image: createImage(spriteStandLeft),
        cropWidth: 177,
        width: standWidth,
      },
      runRight: {
        image: createImage(spriteRunRight),
        cropWidth: 341,
        width: runWidth,
      },
      runLeft: {
        image: createImage(spriteRunLeft),
        cropWidth: 341,
        width: runWidth,
      },
    };
    this.currentSprite = this.sprites.standRight;
    this.canvas = canvas;
    this.canvasContext = canvasContext;
    this.frameRate = frameRate;
  }
  draw() {
    this.canvasContext.drawImage(
      this.currentSprite.image,
      this.currentSprite.cropWidth * this.frames,
      0,
      this.currentSprite.cropWidth,
      400,
      this.position.x,
      this.position.y,
      this.currentSprite.width,
      this.height
    );
  }
  update() {
    this.frames += this.frameRate;
    if (
      this.frames > 28 &&
      (this.currentSprite === this.sprites.standRight ||
        this.currentSprite === this.sprites.standLeft)
    ) {
      this.frames = 0;
    } else if (
      this.frames > 29 &&
      (this.currentSprite === this.sprites.runRight ||
        this.currentSprite === this.sprites.runLeft)
    ) {
      this.frames = 0;
    }
    this.draw();
    this.position.y += this.velocity.y;
    this.position.x += this.velocity.x;
    if (this.position.y < 0) {
      this.velocity.y = 0;
      this.position.y = 0;
    }
    if (this.position.y + this.height + this.velocity.y <= this.canvas.height) {
      this.velocity.y += this.gravity;
    } else {
      this.velocity.y = 0;
    }
  }
}
