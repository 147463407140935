export function createImage(imageSrc) {
  const image = new Image();
  image.src = imageSrc;
  image.style.opacity = 0.5;
  return image;
}
export let constants = {
  gravity: 1,
  mobileGravity: 10,
};
