export class BackgroundObject {
  constructor({ x, y, image, canvasContext }) {
    this.position = {
      x,
      y,
    };
    this.width = image.width;
    this.height = image.height;
    this.color = "blue";
    this.image = image;
    this.canvasContext = canvasContext;
  }
  draw() {
    this.canvasContext.drawImage(this.image, this.position.x, this.position.y);
  }
}

export class Platform {
  constructor({ x, y, image, canvasContext }) {
    this.position = {
      x,
      y,
    };
    this.width = image.width || 580;
    this.height = image.height;
    this.color = "blue";
    this.image = image;
    this.canvasContext = canvasContext;
  }
  draw() {
    this.canvasContext.drawImage(this.image, this.position.x, this.position.y);
  }
}

export class Background {
  constructor({ x, y, image, images, canvasContext }) {
    this.position = {
      x,
      y,
    };
    this.width = image.width || 580;
    this.height = image.height;
    this.image = image;
    this.images = images;
    this.imageObj = this.images[0];
    this.canvasContext = canvasContext;
  }
  draw(scrollOffset) {
    this.images.forEach((imageObj) => {
      if (imageObj.from < scrollOffset && imageObj.to > scrollOffset) {
        this.image = imageObj.image;
        this.imageObj = imageObj;
      }
    });
    if (!this.image) {
      this.image = images[0].image;
      this.imageObj = images[0];
    }
    this.canvasContext.drawImage(
      this.image,
      this.position.x,
      this.position.y,
      this.imageObj.posX,
      this.imageObj.posY
    );
  }
  changeBackgroundImage(image) {
    this.image = image;
  }
}
